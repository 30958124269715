import React from "react";
import css from './latestNewsTemplate.module.scss';
import {Link} from "gatsby";
import NewsDate from "../../shared/NewsDate/NewsDate";

const LatestNewsTemplate = props => {
  const {
    external = false,
    link = '',
    content,
    title,
    date
  } = props;

  const renderTitle = () => {
    if (!link) {
      return title;
    }
    if (external) {
      return (<a href={link} target="_blank">{title}</a>);
    }
    return (<Link to={link}>{title}</Link>);
  };

  return (
    <div className={css.wrapper}>
      <div className={css.content}>{content}</div>
      <div className={css.textWrapper}>
        <div className={css.textWrapperInner}>
          <div className={css.title}>{renderTitle()}</div>
          <div className={css.date}><NewsDate date={date} showClock={false} /></div>
        </div>
      </div>
    </div>
  )
};

export default LatestNewsTemplate;
